<template>
  <div class="card shadow p-2">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <form id="form" @submit.prevent="UpdateSystemSetting">
          <div class="row">
            <div class="col-lg-6">
              <div class="fom-group">
                <label for="">Cron run time</label>
                <input
                  v-model="form.cron_run_time"
                  placeholder="Cron run time"
                  type="time"
                  class="form-control"
                  name="cron_run_time"
                />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label for="">Invoice Generation Days</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.create_invoice_days"
                  name=""
                  id=""
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="fom-group">
                <label for="after_invoice_suspended_days"
                  >Users Invoice Suspended Days After</label
                >
                <input
                  v-model="form.after_invoice_suspended_days"
                  placeholder="Ex: 30/60"
                  type="number"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-lg-6 mt-1">
              <div class="fom-group">
                <label for="after_users_account_delete_days"
                  >Users Account Deleted Days After</label
                >
                <input
                  v-model="form.after_users_account_delete_days"
                  placeholder="Ex: 60/90"
                  type="number"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="row mt-1 justify-content-center">
            <div class="col-lg-6">
              <button type="submit" class="btn btn-primary btn-block">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import vSelect from "vue-select";
import axios from "axios";

export default {
  name: "GeneralSetting",
  components: {
    vSelect,
  },
  data() {
    return {
      form: new Form({
        cron_run_time: "",
        create_invoice_days: "",
        cron_run_time: "",
        connection_type: "",
        after_invoice_suspended_days: "",
        after_users_account_delete_days: "",
      }),
      loading: true,
      connection_types: [
        { name: "Only Mikrotik", value: "mikrotik" },
        { name: "Only Radius", value: "radius" },
        { name: "Mikrotik with radius", value: "mikrotik_radius" },
      ],
    };
  },

  created() {
    this.getSystemSettings();
  },
  methods: {
    UpdateSystemSetting() {
      this.form.post("system/settings").then((resp) => {
        if (resp.data.success) {
          this.$success_message(resp.data.message);
          window.location.reload();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },

    getSystemSettings() {
      axios
        .get("get/system/settings")
        .then((res) => {
          this.loading = false;
          res.data.forEach((setting) => {
            this.setSlug(setting);
          });
        })
        .catch((error) => console.log(error));
    },
    setSlug(setting) {
      if (setting.slug == "cron_run_time")
        this.form.cron_run_time = setting.value;
      // create_invoice_days
      if (setting.slug == "create_invoice_days")
        this.form.create_invoice_days = setting.value;
      // connection_type
      if (setting.slug == "connection_type")
        this.form.connection_type = setting.value;
      // after_invoice_suspended_days
      if (setting.slug == "after_invoice_suspended_days")
        this.form.after_invoice_suspended_days = setting.value;
      // after_users_account_delete_days
      if (setting.slug == "after_users_account_delete_days")
        this.form.after_users_account_delete_days = setting.value;
    },
  },
};
</script>
